.login-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;

  .card {
    align-items: center;
    box-shadow: 10px 0 60px 0 rgba(0 0 3);
    height: 500px;
    padding: 1rem;
    width: 1000px;

    .card-body {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
    }

    .card-img-top {
      width: 25%;
    }

    .card-text {
      width: 100%;
    }

    form {
      .form-group {
        margin-bottom: 1rem;

        button {
          font-size: 1.2rem;
          text-transform: uppercase;
          width: 100%;
        }
      }
    }
  }
}
