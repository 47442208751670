.sidebar {
  background: #000000;
  bottom: 0;
  left: 0;
  min-height: 100vh;
  position: fixed;
  text-align: center;
  top: 0;
  width: 250px;

  a {
    color: #818181;
    display: block;
    font-size: 25px;
    padding: 6px 8px 6px 16px;
    text-decoration: none;
  }

  a:hover {
    color: #f1f1f1;
  }

  .img-wrapper {
    margin: 20px 0;
    text-align: center;
    width: 100%;

    img {
      width: 50%;
    }
  }
}
